@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(shareCardBg.css);


@layer utilities {
  @media (prefers-color-scheme: default) {
    html {
      @apply bg-bgLight;
    }
  }

  @media (prefers-color-scheme: dark) {
    html {
      @apply bg-bgDark;
    }
  }

  .animation-timeline-view {
    animation-timeline: view();
  }

  .noScrollBar::-webkit-scrollbar {
    display: none;
  }

  .noScrollBar {
    scrollbar-width: none;
    /* 隐藏火狐的滚动条 */
    -ms-overflow-style: none;
    /* 隐藏IE和Edge的滚动条 */
  }

  .aTagStyle a {
    text-decoration: underline;
    color: #0c9849;
    word-break: break-all;
  }

  *[class="day"] {
    @apply duration-300;
    @apply animate-show;
    @apply opacity-0;
    @apply cursor-pointer
  }
}

.ProseMirror {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

/* #root {
  height: 100vh;
  width: 100vw;
} */

/* 引入谷歌Serif字体 */
.noto-serif-sc-light {
  font-family: "Noto Serif SC", serif;
  font-weight: 300;
  font-style: normal;
}

.noto-serif-sc-regular {
  font-family: "Noto Serif SC", serif;
  font-weight: 400;
  font-style: normal;
}

.noto-serif-sc-semibold {
  font-family: "Noto Serif SC", serif;
  font-weight: 600;
  font-style: normal;
}

.noto-serif-sc-black {
  font-family: "Noto Serif SC", serif;
  font-weight: 900;
  font-style: normal;
}

a:hover {
  color: inherit;
  /* 继承父元素的颜色 */
  text-decoration: none;
  /* 可选：去掉下划线 */
}